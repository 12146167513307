import styled from "styled-components";
import { sizes, breakpoints } from "../../../variables";

export const CalendarWrapper = styled.div`
  padding-top: 25px;
  padding-bottom: 25px;

  @media only screen and (max-width: ${breakpoints.tablet}) {
    margin-right: 16px;
    margin-left: 16px;
  }
`;

export const StyledCard = styled.div`
  &.wow {
    animation: fadeInUp 0.3s;
  }
  position: relative;
  overflow: hidden;
  border-radius: 2rem;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;
  &:hover {
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  }
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 1.5rem;
  @media (min-width: 640px) {
    padding: 2rem;
    flex-direction: row;
    align-items: flex-start;
  }
  @media (min-width: 768px) {
    padding: 1.5rem 2rem;
  }
  @media (min-width: 1280px) {
    padding: 2rem 1.25rem;
  }
`;

export const StyledText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding-bottom: 1.5rem;

  h3 {
    margin-bottom: 0.25rem;
    font-size: 1rem;
    font-weight: 500;
    color: #333333;
  }
  p {
    font-size: 0.75rem;
    color: #666666;
  }
  @media (min-width: 640px) {
    max-width: 30%;
    align-items: flex-start;
    text-align: left;
    padding-bottom: 0px;
  }
`;

export const InfoSection = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 1.5rem;
  div {
    display: inline-block;
  }
  h3 {
    margin-bottom: 0.25rem;
    font-size: 1rem;
    font-weight: 500;
    color: #333333;
    &.dark {
      color: #ffffff;
    }
  }
  p {
    font-size: 1rem;
    color: #666666;
    text-align: center;
  }
  @media (min-width: 640px) {
    padding-bottom: 0px;
    p {
      text-align: left;
    }
  }
`;

export const Title = styled.h3`
  a {
    margin-bottom: 1rem;
    display: block;
    font-size: 1rem;
    font-weight: bold;
    color: #000000;
    &:hover {
      color: #007bff;
    }
    &.dark {
      color: #ffffff;
      &:hover {
        color: #007bff;
      }
    }
  }
`;

export const ActionSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  div {
    cursor: pointer;
    border-radius: 2rem;
    padding: 1rem 2.25rem;
    font-size: 1rem;
    font-weight: 500;
    color: #ffffff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s;
    a {
      color: #ffffff;
      text-decoration: none;
    }
    &.primary {
      background-color: rgb(0, 180, 205);
      &:hover {
        background-color: rgb(0, 140, 160);
      }
    }
    &.disabled {
      background-color: #cccccc;
      cursor: not-allowed;
    }
  }
  p {
    margin-top: 0.25rem;
    font-size: 1rem;
    color: #666666;
  }
`;
