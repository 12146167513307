import React from "react";
import { ProgramJunior, ProgramSenior } from "../../components";
import Hero from "../../components/reusable/Hero/Hero";
import Student from "../../components/reusable/Student/Student";
import Calendar from "../../components/reusable/Calendar/Calendar";
import InfoCards from "../../components/reusable/InfoCards/InfoCards";
import Questions from "../../components/reusable/Questions/Questions";
import Process from "../../components/reusable/Process/Process";
import Price from "../../components/reusable/Price/Price";
import NewsletterSection from "../../components/reusable/Newsletter/NewsletterSection";

import {
  acadStudentArr,
  academyCardArr,
  heroObjThree,
  infoObjTwo,
  titleObjTwo,
  acadPriceArrJunior,
  acadPriceArrSenior,
  programAcademy,
  programArr1Senior,
  acadQuestionsArr,
  processArrAcad,
  acadPrice,
} from "./Data";

function Academy({ showProgramTable, showNewsletter }) {
  /* show or hide ProgramTable*/
  showProgramTable = false;
  showNewsletter = false;
  const showProgramTableSenior = false;

  return (
    <>
      <Hero {...heroObjThree} />
      {/*<Info {...infoObjTwo} />*/}
      <Price
        dataJunior={acadPriceArrSenior}
        {...titleObjTwo}
        {...acadPrice}
        {...heroObjThree}
      />
      <Calendar data={programAcademy} {...titleObjTwo} />
      <InfoCards data={academyCardArr} {...titleObjTwo} />
      <Student data={acadStudentArr} {...titleObjTwo} />
      <Process data={processArrAcad} />

      {/*showNewsletter && <NewsletterSection />*/}
      <Questions data={acadQuestionsArr} {...infoObjTwo} />
    </>
  );
}

export default Academy;
