import React from "react";
import { Container, Wrapper, ContainerWrapper, Title } from "../styled";

import {
  PriceContainer,
  PriceInfo,
  PriceRow,
  PriceIcon,
  PriceText,
  ButtonPrice,
  PriceRectangle,
  PriceArea,
  PriceWrapper,
  TitlePrice,
  RegisterWrapper,
} from "./Price.elements";
import { RegisterButton } from "../Hero/RegisterButton";

function Price({
  mainPriceTitle,
  dataJunior,
  dataSenior,
  price,
  formJunior,
  formSenior,
  isDisabled,
  showLogInButton,
  showNewsletter,
  showBorderBottom,
}) {
  return (
    <Container id="price">
      <Wrapper>
        <PriceWrapper>
          <TitlePrice>{mainPriceTitle}</TitlePrice>
          <ContainerWrapper>
            <PriceInfo>
              {dataJunior?.map((item) => {
                return (
                  <>
                    <PriceText>{item.text}</PriceText>
                    <PriceText>{item.text2}</PriceText>
                  </>
                );
              })}
            </PriceInfo>
          </ContainerWrapper>
        </PriceWrapper>
      </Wrapper>
    </Container>
  );
}

export default Price;
