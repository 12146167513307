import React from "react";
import { Wrapper, Container, TitleCenter } from "../styled";
import {
  CalendarWrapper,
  StyledCard,
  StyledContent,
  InfoSection,
  Title,
  ActionSection,
  StyledText,
} from "./Calendar.elements";

function Calendar({ data, mainCalendarTitile }) {
  return (
    <Container>
      <Wrapper>
        <TitleCenter>{mainCalendarTitile}</TitleCenter>
        {data.map((data) => {
          return (
            <CalendarWrapper>
              <StyledCard className="wow fadeInUp" data-wow-delay=".1s">
                <StyledContent>
                  <StyledText>
                    <Title>{data.topic}</Title>
                    <p>{data.description}</p>
                  </StyledText>
                  <InfoSection>
                    <div>
                      <p>{data.date}</p>
                      <p>{data.location}</p>
                    </div>
                  </InfoSection>

                  <ActionSection>
                    {data.openRegistration ? (
                      <div className="primary">
                        <a href={data.form} target="blank">
                          Přihlásit se
                        </a>
                      </div>
                    ) : (
                      <div className="disabled">
                        Přihlašování od {data.registrationDate}
                      </div>
                    )}
                    <p>{data.price}</p>
                  </ActionSection>
                </StyledContent>
              </StyledCard>
            </CalendarWrapper>
          );
        })}
      </Wrapper>
    </Container>
  );
}

export default Calendar;
